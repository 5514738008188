import fetch from '../utils/fetch'

export function getWechatPreAuthUrl (query) {
  return fetch({
    url: '/wechat/pre_auth_url',
    method: 'get',
    params: query
  })
}

export function getWechatBind (query) {
  return fetch({
    url: '/wechat/bind',
    method: 'post',
    params: query
  })
}

export function getAuthorizerInfo () {
  return fetch({
    url: 'wechat/authorizerinfo',
    method: 'get',
  })
}

export function getWechatKfs () {
  return fetch({
    url: '/wechat/kfs',
    method: 'get'
  })
}

export function createWechatKfs (query) {
  return fetch({
    url: '/wechat/kfs',
    method: 'post',
    data: query
  })
}

export function updateWechatKfs (query) {
  return fetch({
    url: '/wechat/update/kfs',
    method: 'post',
    data: query
  })
}

export function deleteWechatKfs (query) {
  return fetch({
    url: '/wechat/kfs',
    method: 'delete',
    params: query
  })
}

export function uploadMaterial (query) {
  return fetch({
    url: '/wechat/material',
    method: 'post',
    data: query
  })
}

export function getWechatMaterial (query) {
  return fetch({
    url: '/wechat/material',
    method: 'get',
    params: query
  })
}

export function getMaterialStats (query) {
  return fetch({
    url: '/wechat/material/stats',
    method: 'get'
  })
}

export function deleteWechatMaterial (query) {
  return fetch({
    url: '/wechat/material',
    method: 'delete',
    params: query
  })
}

export function pushNewsImage (file) {
  return fetch({
    url: '/wechat/news/image',
    method: 'post',
    data: file,
  })
}

export function getOpenKfReply () {
  return fetch({
    url: '/wechat/openkf/reply',
    method: 'get',
  })
}

export function setOpenKfReply (query) {
  return fetch({
    url: '/wechat/openkf/reply',
    method: 'post',
    data: query,
  })
}

export function setSubscribeReply (query) {
  return fetch({
    url: '/wechat/subscribe/reply',
    method: 'post',
    params: query,
  })
}

export function getSubscribeReply (query) {
  return fetch({
    url: '/wechat/subscribe/reply',
    method: 'get',
    params: query,
  })
}

export function setDefaultReply (query) {
  return fetch({
    url: '/wechat/default/reply',
    method: 'post',
    data: query,
  })
}

export function getDefaultReply (query) {
  return fetch({
    url: '/wechat/default/reply',
    method: 'get',
    params: query,
  })
}

export function addKeywordReply (query) {
  return fetch({
    url: '/wechat/keyword/reply',
    method: 'post',
    data: query,
  })
}

export function updateKeywordReply(query) {
  return fetch({
    url: '/wechat/keyword/reply',
    method: 'put',
    data: query,
  })
}

export function deleteKeywordReply(query) {
  return fetch({
    url: '/wechat/keyword/reply',
    method: 'delete',
    params: query,
  })
}


export function getKeywordReply () {
  return fetch({
    url: '/wechat/keyword/reply',
    method: 'get',
  })
}

export function createArtical (query) {
  return fetch({
    url: '/wechat/news',
    method: 'post',
    data: query
  })
}

export function updateArtical (query) {
  return fetch({
    url: '/wechat/news',
    method: 'put',
    data: query
  })
}

export function getArtical (media_id) {
  return fetch({
    url: '/wechat/news/' + media_id,
    method: 'get',
  })
}

export function getUserWeekSummary () {
  return fetch({
    url: '/wechat/stats/userweeksummary',
    method: 'get',
  })
}

export function openUserPlatform() {
  return fetch({
    url: '/wechat/open',
    method: 'post',
  })
}

export function getWorkWechatConfig () {
  return fetch({
    url: '/workwechat/config',
    method: 'get'
  })
}

export function setWorkWechatConfig (params) {
  return fetch({
    url: '/workwechat/config',
    method: 'post',
    data: params
  })
}

export function getWorkWechatRelList(salesperson_id, params) {
  return fetch({
    url: '/workwechat/rellist/' + salesperson_id,
    method: 'get',
    data: params
  })
}

export function getWorkWechatRelLogsList(user_id, params) {
  return fetch({
    url: '/workwechat/rellogs/' + user_id,
    method: 'get',
    data: params
  })
}

export function getWorkWechatTemplateList () {
  return fetch({
    url: '/workwechat/messagetemplate',
    method: 'get',
  })
}

export function getWorkWechatTemplate (template_id) {
  return fetch({
    url: '/workwechat/messagetemplate/' + template_id,
    method: 'get',
  })
}

export function saveWorkWechatTemplate (template_id, params) {
  return fetch({
    url: '/workwechat/messagetemplate/' + template_id,
    method: 'put',
    data: params
  })
}

export function openWorkWechatTemplate (template_id, params) {
  return fetch({
    url: '/workwechat/messagetemplate/open/' + template_id,
    method: 'put',
    data: params
  })
}

export function closeWorkWechatTemplate (template_id, params) {
  return fetch({
    url: '/workwechat/messagetemplate/close/' + template_id,
    method: 'put',
    data: params
  })
}

export function getUrlScheme(id) {
  return fetch({
    url: '/wechat/urlscheme/' + id,
    method: 'get',
  })
}

export function getUrlSchemeList(query) {
  return fetch({
    url: '/wechat/urlscheme',
    method: 'get',
    params: query
  })
}

export function createUrlScheme(params) {
  return fetch({
    url: '/wechat/urlscheme',
    method: 'post',
    data: params
  })
}

export function updateUrlScheme(id, params) {
  return fetch({
    url: '/wechat/urlscheme/' + id,
    method: 'put',
    data: params
  })
}

export function deleteUrlScheme(id) {
  return fetch({
    url: '/wechat/urlscheme/' + id,
    method: 'delete',
  })
}

// 素材管理文件列表
export function getEspierMaterialFolder(query) {
  return fetch({
    url: '/espier/material/folder',
    method: 'get',
    params: query
  })
}

// 根据文件夹获取素材列表
export function getEspierMaterialList(query) {
  return fetch({
    url: '/espier/material/list',
    method: 'get',
    params: query
  })
}

// 保存素材
export function getEspierMaterialSave(params) {
  return fetch({
    url: '/espier/material/save',
    method: 'post',
    data: params
  })
}

// 添加目录
export function postEspierMaterialFolder(params) {
  return fetch({
    url: '/espier/material/folder',
    method: 'post',
    data: params
  })
}

// 移动文件夹到指定文件夹下
export function getEspierMaterialMovefolder(query) {
  return fetch({
    url: '/espier/material/movefolder',
    method: 'put',
    data: query,
  })
}

// 删除文件夹
export function deleteEspierMaterialFolder(id) {
  return fetch({
    url: '/espier/material/folder/' + id,
    method: 'delete',
  })
}

// 批量删除
export function deleteEspierMaterial(query) {
  return fetch({
    url: '/espier/material',
    method: 'delete',
    params: query
  })
}

// 批量移动素材
export function getEspierMaterialMove(params) {
  return fetch({
    url: '/espier/material/move',
    method: 'post',
    data: params
  })
}
